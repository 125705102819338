import useCollator from '~/composables/useCollator'

const rank: Record<string, number> = {
  'sex': 0,
  'breed': 1,
  'birthdate': 2,
  'registration number': 3,
  'tag': 4,
  'ga bred tag #': 5,
  'tattoo': 6,
  'ear notch': 7,
  'description': 8,
}

export default function useEntryDisplaySort(a: string, b: string) {
  let rankA = rank[a.toLowerCase()]
  let rankB = rank[b.toLowerCase()]

  if (typeof rankA === 'undefined') {
    return (rankA = 9)
  }

  if (typeof rankB === 'undefined') {
    return (rankB = 9)
  }

  const collator = useCollator()

  return collator.compare(a, b)
}
